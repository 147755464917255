// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$toreta-ap-ox-app-primary: mat.define-palette(mat.$indigo-palette);
$toreta-ap-ox-app-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$toreta-ap-ox-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$toreta-ap-ox-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $toreta-ap-ox-app-primary,
      accent: $toreta-ap-ox-app-accent,
      warn: $toreta-ap-ox-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($toreta-ap-ox-app-theme);

/**
 * ここからオリジナル
 */
@import "reset";

html {
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "Meiryo",
    "メイリオ", "Osaka", "MS PGothic", arial, helvetica, sans-serif;
  line-height: 1.5;
  width: 100%;
  display: flex;
  font-size: 62.5%;

  // @See https://toreta.atlassian.net/browse/AP-698
  min-height: 100%;
  height: auto;
}

body {
  flex: 1;
  width: 100%;
  background-color: var(--contents-primary-background-color);
  font-size: 1.6rem;
}

:root {
  /**
   * @TODO 色のルールを確認して整理する #323
   * https://github.com/toreta/toreta-ap-ox-app/issues/323
   */
  --color-white: #ffffff;

  --color-dish-000: #f2eeed;
  --color-dish-100: #f8f7f5;
  --color-dish-200: #b8b2b1;
  --color-dish-300: #7a6c68;
  --color-dish-400: #533e31;
  --color-dish-600: #271b1a;
  --color-dish-700: #d5d0cf;

  --color-tomato-000: #fff3ef;
  --color-tomato-100: #a9413c;
  --color-tomato-200: #df704a;

  // common
  --border-radius-4: 4px;
  --border-radius-6: 6px;

  // contents
  --contents-primary-background-color: var(--color-dish-600);
  --contents-secondary-background-color: var(--color-white);
  --contents-primary-color: var(--color-white);
  --contents-secondary-color: var(--color-dish-600);
  --contents-danger-color: #d9572a;

  // time limit bar
  --time-limit-bar-height: 62px;

  // category tab
  --category-tab-height: 72px;
  --category-tab-background-color: #433332;

  // footer
  --footer-menu-height: 62px;
  --fotter-menu-background-color: #433332;
  --fotter-menu-color: var(--color-white);
  --fotter-menu-color-selected: #df704a;

  // button
  --action-button-primary-background-color: #d9572a;
  --action-button-primary-background-color--disabled: var(--color-dish-200);
  --action-button-primary-color: var(--color-white);

  --action-button-secondary-background-color: var(--color-white);
  --action-button-secondary-color: var(--color-dish-600);

  --action-button-tertiary-background-color: var(--color-white);
  --action-button-tertiary-color: #d9572a;

  --action-button-quaternary-background-color: var(--color-dish-600);
  --action-button-quaternary-border: var(--color-white) solid 1px;

  --delete-button-color: #d9572a;
  --delete-button-border: #d9572a solid 1px;

  // image
  --image-background-color: var(--color-dish-600);

  // sold out
  --sold-out-label-background-color: rgba(0, 0, 0, 0.45);
  --sold-out-label-color: var(--color-white);

  // item list
  --item-list-item-name-font-weight: nomal;

  // menu category
  --book-type-menu-category-name-color: #f8f7f7;
  --book-type-menu-category-name-color-selected: #df704a;

  // variation
  --variation-name-color: #d5d0cf;

  // stamp
  --stamp-note-background-color: #615756;

  // order list
  --order-list-button: 46px;

  // discount
  --discount-ifno-background-color: #433332;
}

/**
 * ボトムシート用
 * 詳細度を上げないとAngularMaterialのcssを上書きできないのでclassをつけている
 */
mat-bottom-sheet-container.BottomSheetPanel {
  min-width: 100vw;
  max-height: 90vh;
  border-radius: 10px 10px 0 0;
  overflow: initial;
  background-color: var(--color-white);
  padding: 10px 0 0 0;
}

// Angular Material Dialog のスタイルを上書き
mat-dialog-container.mat-dialog-container {
  padding: 30px 20px;
}

mat-dialog-container .mat-dialog-actions {
  padding: 0;
  margin-bottom: 0;
}
